<template>
    <el-form
        v-loading="loading"
        element-loading-text="加载中..."
        ref="form"
        :model="formData"
        :rules="rules"
        :label-width="labelWidth"
        class="sc-form"
        size="small"
    >
        <el-row :gutter="16">
            <el-col 
                v-for="(item, index) in visibleColumns" 
                :key="index"
                :span="colSpan"
            >
                <el-form-item 
                    :label="item.label"
                    :prop="item.prop"
                >
                    <!-- 输入框 -->
                    <el-input 
                        v-if="!item.type || item.type === 'input'"
                        v-model="formData[item.prop]"
                        :placeholder="'请输入' + item.label"
                        :readonly="item.editReadonly"
                        :class="{ 'is-readonly': item.editReadonly }"
                        size="small"
                    />
                    <!-- 文本域 -->
                    <el-input
                        v-if="item.type === 'textarea'"
                        v-model="formData[item.prop]"
                        type="textarea"
                        :rows="item.minRows || 2"
                        :placeholder="'请输入' + item.label"
                        :readonly="item.editReadonly"
                        :class="{ 'is-readonly': item.editReadonly }"
                        size="small"
                    />
                    <!-- 上传组件 -->
                    <el-upload
                        v-if="item.type === 'upload'"
                        v-model:file-list="uploadFileLists[item.prop]"
                        :action="'/api' + item.action"
                        :list-type="item.listType || 'picture'"
                        :on-success="(response) => handleUploadSuccess(response, item)"
                        :on-error="handleUploadError"
                        :before-upload="handleBeforeUpload"
                        :disabled="item.editReadonly"
                        :headers="uploadHeaders"
                        :limit="item.limit || 1"
                        :auto-upload="false"
                        :ref="(el) => setUploadRef(el, item.prop)"
                    >
                        <el-button type="primary" :disabled="item.editReadonly" size="small">点击上传</el-button>
                        <template #tip>
                            <div class="el-upload__tip" style="font-size: 11px;">
                                只能上传jpg/png文件
                            </div>
                        </template>
                    </el-upload>
                    <!-- 数字输入框 -->
                    <el-input-number
                        v-if="item.type === 'number'"
                        v-model="formData[item.prop]"
                        :min="item.min ?? 0"
                        :max="item.max"
                        :placeholder="'请输入' + item.label"
                        :disabled="item.editReadonly"
                        :controls-position="item.controlsPosition || 'right'"
                        size="small"
                    />
                    
                    <!-- 选择器 -->
                    <el-select
                        v-if="item.type === 'select' || item.dicUrl"
                        v-model="formData[item.prop]"
                        :placeholder="'请选择' + item.label"
                        :disabled="item.editReadonly"
                        :clearable="true"
                        size="small"
                    >
                        <el-option
                            v-if="item.dicUrl"
                            v-for="dict in dictCache[item.dicUrl]"
                            :key="dict.dictKey"
                            :label="dict.dictValue"
                            :value="String(dict.dictKey)"
                        />
                        <el-option
                            v-else
                            v-for="dict in item.dicData"
                            :key="dict.value"
                            :label="dict.label"
                            :value="String(dict.value)"
                        />
                    </el-select>

                    <!-- 日期选择器 -->
                    <el-date-picker
                        v-if="item.type === 'date'"
                        v-model="formData[item.prop]"
                        type="date"
                        :placeholder="'请选择' + item.label"
                        :disabled="item.editReadonly"
                        size="small"
                    />
                </el-form-item>
            </el-col>
        </el-row>

        <el-form-item class="form-footer">
            <slot name="footer">
                <div class="footer-buttons">
                    <el-button 
                        type="primary" 
                        :loading="loading"
                        @click="debouncedSubmit"
                        size="small"
                    >保存</el-button>
                    <el-button 
                        @click="$emit('cancel')"
                        size="small"
                    >取消</el-button>
                </div>
            </slot>
        </el-form-item>
    </el-form>
</template>

<script>
import axios from '@/axios'
import { getToken } from '@/utils/auth'

export default {
    name: 'ScEdit',
    props: {
        option: {
            type: Object,
            required: true
        },
        modelValue: {
            type: Object,
            default: () => ({})
        },
        labelWidth: {
            type: String,
            default: '120px'
        },
        colSpan: {
            type: Number,
            default: 24
        }
    },
    emits: ['update:modelValue', 'submit', 'cancel'],
    data() {
        return {
            formData: {},
            rules: {},
            loading: false,
            submitTimer: null,
            dictCache: {},
            isDictLoaded: false,
            uploadHeaders: {
                'Sc-Auth': 'bearer ' + getToken()
            },
            uploadRefs: {},  // 存储上传组件的引用
            uploadFileLists: {}  // 存储每个上传组件的文件列表
        }
    },
    watch: {
        modelValue: {
            handler(val) {
                if (!this.isDictLoaded) {
                    return;
                }
                
                if (JSON.stringify(val) === JSON.stringify(this.formData)) {
                    return;
                }
                const processedData = { ...val }
                this.visibleColumns.forEach(item => {
                    if (item.type === 'number' && (processedData[item.prop] === undefined || processedData[item.prop] === null)) {
                        processedData[item.prop] = 0
                    }
                    if ((item.type === 'select' || item.dicUrl) && processedData[item.prop] !== undefined) {
                        processedData[item.prop] = String(processedData[item.prop])
                    }
                })
                this.formData = processedData
            },
            immediate: true,
            deep: true
        },
        formData: {
            handler(val) {
                if (JSON.stringify(val) === JSON.stringify(this.modelValue)) {
                    return; // 如果值相同，不进行更新
                }
                this.$emit('update:modelValue', val)
            },
            deep: true
        }
    },
    created() {
        this.initRules()
        this.loadDictionaryData()
    },
    computed: {
        visibleColumns() {
            // 修改为 editDisplay 判断
            return this.option.column.filter(item => item.editDisplay !== false)
        }
    },
    methods: {
        initRules() {
            this.rules = this.visibleColumns.reduce((acc, item) => {
                if (item.required) {
                    acc[item.prop] = [{
                        required: true,
                        message: `请${item.type === 'select' ? '选择' : '输入'}${item.label}`,
                        trigger: item.type === 'select' ? 'change' : 'blur'
                    }]
                }
                return acc
            }, {})
        },

        // 添加防抖提交
        debouncedSubmit() {
            if (this.submitTimer) {
                clearTimeout(this.submitTimer)
            }
            
            this.submitTimer = setTimeout(() => {
                this.submitFormWithLoading()
            }, 300)
        },

        // 设置上传组件的引用
        setUploadRef(el, prop) {
            if (el) {
                this.uploadRefs[prop] = el;
            }
        },

        // 提交所有待上传的文件
        async submitAllUploads() {
            const uploadPromises = [];
            
            for (const [prop, upload] of Object.entries(this.uploadRefs)) {
                if (upload && this.uploadFileLists[prop]?.length > 0) {
                    const uploadPromise = new Promise((resolve, reject) => {
                        const originalOnSuccess = this.handleUploadSuccess;
                        const originalOnError = this.handleUploadError;
                        
                        // 临时替换成功回调
                        this.handleUploadSuccess = (response, item) => {
                            originalOnSuccess(response, item);
                            resolve(response);
                        };
                        
                        // 临时替换错误回调
                        this.handleUploadError = (error) => {
                            originalOnError(error);
                            reject(error);
                        };
                        
                        upload.submit();
                    });
                    
                    uploadPromises.push(uploadPromise);
                }
            }
            
            if (uploadPromises.length > 0) {
                await Promise.all(uploadPromises);
            }
        },

        // 修改表单提交方法
        async submitFormWithLoading() {
            if (this.loading) return
            
            try {
                await this.$refs.form.validate()
                
                // 创建回调函数
                const done = () => {
                    this.loading = false
                }
                
                const loading = () => {
                    this.loading = true
                }
                
                this.loading = true
                
                try {
                    // 先处理所有文件上传
                    await this.submitAllUploads();
                    
                    // 再提交表单数据
                    await this.$emit('submit', this.formData, done, loading)
                } catch (error) {
                    loading()
                    throw error
                }
            } catch (error) {
                this.loading = false
                console.error(error)
            }
        },

        resetForm() {
            this.$refs.form.resetFields()
        },

        async loadDictionaryData() {
            this.loading = true;
            const dictLoadPromises = [];
            
            for (const column of this.visibleColumns) {
                if (column.dicUrl && !this.dictCache[column.dicUrl]) {
                    const promise = axios.get(column.dicUrl)
                        .then(response => {
                            if (response.data.success) {
                                this.dictCache[column.dicUrl] = response.data.data.map(dict => ({
                                    ...dict,
                                    dictKey: String(dict.dictKey)
                                }))
                            }
                        })
                        .catch(error => {
                            console.error('Failed to load dictionary data:', error)
                        });
                    
                    dictLoadPromises.push(promise);
                }
            }
            
            // 等待所有字典数据加载完成
            await Promise.all(dictLoadPromises);
            this.isDictLoaded = true;
            // 手动触发一次 modelValue 的处理
            this.$nextTick(() => {
                this.modelValue && this.$options.watch.modelValue.handler.call(this, this.modelValue);
            });
            this.loading = false;
        },

        // 处理上传成功
        handleUploadSuccess(response, item) {
            if (response.success) {
                const fileUrl = typeof response.data === 'string' ? response.data : response.data.link;
                this.formData[item.prop] = fileUrl;
                this.$message.success('上传成功');
            } else {
                this.$message.error(response.msg || '上传失败');
            }
        },

        // 处理上传错误
        handleUploadError(error) {
            console.error('上传失败:', error)
            this.$message.error('上传失败，请重试')
        },

        // 上传前的验证
        handleBeforeUpload(file) {
            // 验证文件类型
            const isValidType = ['image/jpeg', 'image/png'].includes(file.type)
            if (!isValidType) {
                this.$message.error('只能上传 JPG/PNG 格式的图片！')
                return false
            }

            // 验证文件大小（默认限制为 2MB）
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB！')
                return false
            }

            return true
        }
    },
    
    // 组件销毁时清理定时器
    beforeDestroy() {
        if (this.submitTimer) {
            clearTimeout(this.submitTimer)
        }
    }
}
</script>

<style scoped>
.sc-form {
    padding: 16px;
    max-height: calc(100vh - 160px);
    overflow-y: auto;
    position: relative;
}

.form-footer {
    text-align: center;
    margin-top: 16px;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 8px 0;
    margin-bottom: 0;
}

.footer-buttons {
    display: flex;
    justify-content: center;
    gap: 8px;
}

:deep(.el-form-item) {
    margin-bottom: 14px;
}


:deep(.is-readonly) {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #909399;
    cursor: not-allowed;
}

:deep(.is-readonly:hover) {
    border-color: #e4e7ed;
}

:deep(.is-readonly .el-input__inner) {
    cursor: not-allowed;
    background-color: #f5f7fa;
    color: #909399;
}

:deep(.el-form-item.form-footer) {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

:deep(.el-form-item.form-footer .el-form-item__content) {
  width: 100%;
  margin-left: 0 !important;  /* 覆盖默认的 margin-left */
}

.footer-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

:deep(.el-form-item__label) {
  font-size: 11px;
  line-height: 28px;
}

:deep(.el-input__inner),
:deep(.el-textarea__inner),
:deep(.el-select__input),
:deep(.el-input-number__decrease),
:deep(.el-input-number__increase),
:deep(.el-input-number__input),
:deep(.el-select-dropdown__item),
:deep(.el-button) {
  font-size: 11px;
  height: 28px;
  line-height: 28px;
}

:deep(.el-textarea__inner) {
  line-height: 1.5;
}

:deep(.el-form--small .el-form-item__label) {
  line-height: 28px;
}

:deep(.el-select-dropdown__item) {
  height: 28px;
  line-height: 28px;
}

:deep(.el-button) {
  padding: 6px 12px;
}
</style>
